import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className='nova__footer'>
      <div className='nova__footer-copyright'>
        <p>© 2023 ObjectNova, Inc.</p>
      </div>
    </div>
  )
}

export default Footer