import React, { useState, useEffect } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';


import './navbar.css';

const Menu = () => (
    <>
    <p><a href="#home">Home</a></p>
    <p><a href="#whatWeDo">What We Do</a></p>
    <p><a href="#whoWeAre">Who We Are</a></p>
    <p><a href="#getInTouch">Get In Touch</a></p>
    <p><a href="#careers">Careers</a></p>
    </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isHidden, setHidden] = useState(false);
  const [isAtTop, setAtTop] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const scrollDown = currentScrollPos > prevScrollPos;

      // Update the previous scroll position
      setPrevScrollPos(currentScrollPos);

      // If scrolling down, hide the navbar
      if (scrollDown) {
        setHidden(true);
      } else {
        // If scrolling up, show the navbar
        setHidden(false);
      }

      // Check if at the very top
      setAtTop(currentScrollPos === 0);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);
  
  return (
    <div className={`navbar ${isHidden ? 'hidden' : 'visible'} ${isAtTop ? 'at-top' : ''}`}>
        <div className='nova__navbar'>
            <div className='nova__navbar-links'>
                <div className="nova__navbar-links_logo">
                    <span className='gradient__text'>ObjectNova</span>
                </div>
                <div className='nova__navbar-links_container'>
                    <Menu />
                </div>
            </div>
            <div className='nova__navbar-menu'>
                {toggleMenu
                ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
                {toggleMenu && (
                <div className="nova__navbar-menu_container scale-up-center">
                    <div className="nova__navbar-menu_container-links">
                        <Menu />
                    </div>
                </div>
                )} 
            </div>
        </div>
    </div>
  )
}

export default Navbar