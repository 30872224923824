import React from 'react';
import './header.css';
// import logo from '../../assets/nova_logo.png';

const Header = () => {
  return (
    <div className='nova__header section__padding' id='home'>
      <div className='nova__header-content'>
        <h1 className='nonGradient__text'>
          Our company assists businesses in taking on their most
          <h1 className='gradient__text'> ambitious technological </h1>
           projects and developing new capabilities.
        </h1>
        <p>Our company is utilizing cloud-native software and product engineering, transforming how businesses leverage technology to drive change.</p>

        <div className='nova__header-content__input'>
          <button type='button' ><a href="#getInTouch">Your next project &rarr;</a></button>
        </div>

          
      </div>
      {/* <div className='nova__header-image'>
          <img src={logo} alt='logo' />
      </div> */}
    </div>
  )
}

export default Header