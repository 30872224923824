import React from 'react';
import './whoWeAre.css';

const WhoWeAre = () => {
  return (
    <div className='nova__whoWeAre section__padding section__margin' id='whoWeAre'>
      <div className='nova__whoWeAre-heading'>
        <h1 className='gradient__text'>Who We Are</h1>
      </div>
      <div className='nova__whoWeAre-content'>
        
        <p>ObjectNova Inc. is a modern consulting firm that was founded in 1999 and incorporated in the Province of Ontario, Canada in 2000. We specialize in strategy, technology, and business transformation, and have successfully managed web hosting, infrastructure services, and software consulting since our inception. As the world changes rapidly, we have rebooted our business in 2023 to ensure modern alignment with new and ever-changing industry and client needs. Our headquarters are located in Toronto, Canada, and our employees are located across the world. We serve clients in North America, Europe, and Asia Pacific, and have provided technology services to thousands of clients over the years. We assure the highest standards of customer satisfaction, solution quality, and technology platform resiliency, and continuously improve our service offering.</p>

        
      </div>
      {/* <div className='nova__whoWeAre-image'>
          <img src={} alt='' />
      </div> */}
        
    </div>
  )
}

export default WhoWeAre