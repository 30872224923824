import React, { useState } from "react";
import axios from "axios";
import "./getInTouch.css";
import ReCAPTCHA from "react-google-recaptcha";

const GetInTouch = () => {
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    inquiry: "",
    isChecked: false,
    isVerified: false,
  });

  //const [isDisabled, setisDisabled] = useState(true);

  const handleCaptchaChange = (e) => {
    setFormData((prevState) => ({ ...prevState, isVerified: !!e }));
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      isChecked: !prevState.isChecked,
    }));
    setShowRecaptcha(!showRecaptcha);
  };

  const handleSubmit = (e) => {
    console.log(formData);
    if (!formData.name || !formData.email || !formData.inquiry) {
      alert("Please fill out all required fields");
      return;
    }
    if (!formData.isChecked) {
      alert("Please agree to the privacy policy");
      return;
    }
    if (!formData.isVerified) {
      alert("Please verify that you are not a robot");
      return;
    }
    e.preventDefault();
    console.log("Form Submission", formData);
    axios
      .post("http://internaldevapi.objectnova.ca/send", formData)
      .then((response) => {
        console.log(response);
        setFormData({
          name: "",
          email: "",
          phone: "",
          inquiry: "",
          isChecked: false,
        });
      })
      .catch((error) => {
        console.log(error);
        setFormData({
          name: "",
          email: "",
          phone: "",
          inquiry: "",
        });
      });
  };

  return (
    <div className="nova__getInTouch section__padding" id="getInTouch">
      <div className="nova__getInTouch-heading">
        <h1 className="gradient__text">Get In Touch</h1>
      </div>
      <div className="nova__getInTouch-content">
        <div className="nova__getInTouch-content__input">
          <form onSubmit={handleSubmit}>
            <label>
              <input
                type="text"
                name="name"
                placeholder="Name*"
                value={formData.name}
                onChange={handleChange}
                className="text__input"
              />
            </label>

            <label>
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                className="text__input"
              />
            </label>

            <label>
              <input
                type="email"
                name="email"
                placeholder="Email*"
                value={formData.email}
                onChange={handleChange}
                className="text__input"
              />
            </label>

            <label>
              <textarea
                name="inquiry"
                placeholder="How can we help you?*"
                value={formData.inquiry}
                onChange={handleChange}
                className="text__input"
              />
            </label>

            <label className="checkbox">
              <input type="checkbox" onChange={handleCheckboxChange} />
              By submitting, you consent to ObjectNova Inc. processing your
              information in accordance with our Privacy Policy. We take your
              privacy seriously; opt out of email updates at any time.
            </label>

            {showRecaptcha && (
              <ReCAPTCHA
                sitekey="6Lep4DIpAAAAAKz7vZm22K47XCJO2MoGMcP8lGxB"
                onChange={handleCaptchaChange}
              />
            )}

            <button className="submit" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
