import React from 'react';
import { Header, WhoWeAre, WhatWeDo, Careers, GetInTouch, Footer } from './containers';
import { Navbar } from './components';
import './App.css';

const App = () => {
  return (
    <div className='App'>
      <div className='gradient__bg'>
        <div className='app__margin'>
          <Navbar />
          <Header />
          <WhatWeDo />
          <WhoWeAre />
          <GetInTouch />
          <Careers />
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default App