import React from 'react';
import './whatWeDo.css';
import { Feature } from '../../components';

const featuresData = [
  {
    title: 'Unlock the value of your data and AI',
    text: "ObjectNova leverages data and AI to help people and organizations solve the world’s biggest problems. We enable real-time insights, build modern data products, and accelerate your business with AI. Join us on the journey to build a better tomorrow for all.",
  },
  {
    title: 'We deliver with you',
    text: 'New technologies. Shifting customers. Industry disruption. Business moves fast, and we specialize in working through change with you.',
  },
  {
    title: 'Our Expertise:',
    text: <ul className='expertiseMargin'>
            <li>Process optimization</li>
            <li>Business model change</li>
            <li>Cloud transformation</li>
            <li>Agile transformation</li>
            <li>Change management</li>
            <li>Employee engagement</li>
          </ul>,
  },
  {
    title: 'Legacy - Not so Fast!',
    text: 'At ObjectNova, we understand that many companies continue to rely on legacy technology. We know that it can be a major effort to shift to new technologies, but don’t worry, we’ve got you covered. We can assist in supporting your current technology stack, help you create plans to modernize, and execute on those goals.',
  },
]



const WhatWeDo = () => {
  return (
    <div className='nova__whatWeDo section__padding' id='whatWeDo'>
      <div className='nova__whatWeDo-heading'>
        <h1 className='gradient__text'>What We Do</h1>
      </div>
      <div className='nova__whatWeDo-container'>
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index}/>
        ))}
      </div>
    </div>
  )
}

export default WhatWeDo;